input.nepDate {
	display: inline;
	position: absolute; 
	left:8px;
	top: 0px;
	border: none !important;
	background: transparent;
	text-align: left;
	color: #AAA;
	font-size: 100%;
	max-width: 5rem;
}
input.nepDate:focus {
	outline: none;
}
.nepali-date-picker .drop-down-content {
	width: inherit !important;
}

[v-cloak] {
    display: none !important;
}

/* tweak datatable for script reports */
/* the right scrollbar is causing a bottom scrollbar to appear */
/* .dt-scrollable {
	overflow-x: hidden;
} */
/* empty columns are causing problems with sizing, specifically with the first column on left (row numbers) */
.dt-cell__content {
	min-width: 50px;
}
